import { Component, OnInit, Input } from '@angular/core';
import { ImageObject } from '../models/image-object';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-image-carrousel',
  templateUrl: './image-carrousel.component.html',
  styleUrls: ['./image-carrousel.component.scss']
})
export class ImageCarrouselComponent implements OnInit {

  @Input()
  images: ImageObject[];

  @Input()
  image: string;

  //VM FEST
  @Input()
  isVMFest: boolean;

  @Input()
  cover: boolean = false;

  currentCarrouselIndex: number = 0;
  environment: any;

  constructor() {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

  nextPicture() {
    this.currentCarrouselIndex++;
  }

  previousPicture() {
    this.currentCarrouselIndex--;
  }

}
