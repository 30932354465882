<div *ngIf="loading" class="loading-panel" fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner></mat-spinner>
  <div class="loading-text">{{'Please wait this might take a few seconds...' | translate }} </div>
</div>

<div *ngIf="!isEdit" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
  fxLayoutAlign.xs="start start">
  <h2 class="mat-title" translate>Add an event</h2>
  <button (click)="importFromFacebook()" mat-raised-button class="button-import-facebook"><span translate>Import from
      Facebook</span></button>
</div>

<div #eventFormContainer>
  <form *ngIf="(isEdit && event) || !isEdit" [formGroup]="eventForm" #eventFormRef="ngForm" class="event-form"
    (ngSubmit)="saveEvent()" novalidate>
    <div class="mat-subheading-2">{{'Title' | translate }}&nbsp;<span class="compulsory">*</span></div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutGap="10px">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label translate>Event title in Norwegian</mat-label>
        <input autocomplete="off" trim="blur" class="language-field norwegian" matInput #title_nb
          [maxlength]="maxCharsTitle.toString()" formControlName="title_nb">
        <mat-hint align="end">{{title_nb.value.length}} / {{maxCharsTitle}}</mat-hint>
        <mat-error *ngIf="eventForm.hasError('required',['title_nb'])" translate>
          Title in Norwegian is required
        </mat-error>
        <mat-error *ngIf="eventForm.hasError('maxlength',['title_nb'])">
          <span translate>Maximum length is</span> {{maxCharsTitle}} <span translate>chars</span>
        </mat-error>
        <mat-error *ngIf="eventForm.hasError('validEventTitle',['title_nb'])" translate>
          Event title must be unique
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label translate>Event title in English</mat-label>
        <input autocomplete="off" trim="blur" class="language-field english" matInput #title_en
          [maxlength]="maxCharsTitle.toString()" formControlName="title_en">
        <mat-hint align="end">{{title_en.value.length}} / {{maxCharsTitle}}</mat-hint>
        <mat-error *ngIf="eventForm.hasError('maxlength',['title_en'])">
          <span translate>Maximum length is</span> {{maxCharsTitle}} <span translate>chars</span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mat-subheading-2" translate>Description</div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between start">
      <div fxFlex="50" fxLayout="column" class="full-width" fxLayoutAlign="start start">
        <ckeditor *ngIf="isBrowser" class="full-width" matInput [editor]="Editor" [config]="configEditorNb" #desc_nb
          formControlName="desc_nb"></ckeditor>
        <div class="hint">{{eventForm.controls.desc_nb.value.length}} / {{maxCharsDesc}}</div>
        <div class="error-field" *ngIf="eventForm.hasError('maxlength',['desc_nb'])">
          {{'Maximum length is' | translate}} {{maxCharsDesc}} {{'chars' | translate}}
        </div>
      </div>
      <div fxFlex="50" fxLayout="column" class="full-width" fxLayoutAlign="start start">
        <ckeditor *ngIf="isBrowser" class="full-width" matInput [editor]="Editor" [config]="configEditorEn"
          formControlName="desc_en"></ckeditor>
        <div class="hint">{{eventForm.controls.desc_en.value.length}} / {{maxCharsDesc}}</div>
        <div class="error-field" *ngIf="eventForm.hasError('maxlength',['desc_en'])">
          {{'Maximum length is' | translate}} {{maxCharsDesc}} {{'chars' | translate}}
        </div>
      </div>
    </div>

    <div *ngIf="environment.preselectedCategories.length === 0" fxLayout="column">
      <div class="mat-subheading-2" #catContainer><span translate>Categories</span>&nbsp;<span
          class="compulsory">*</span>
      </div>
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" class="margin-bottom">
        <div fxFlex="100" class="hint" ngClass.xs="full-width" align="start"><span translate>Select at least one
            category.</span><span *ngIf="environment.ui.showOnForm.maxCategories != -1"> {{'Maximum' | translate }}
            {{environment.ui.showOnForm.maxCategories}}.</span></div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" ngClass.xs="full-width"
          class="container-checkbox" [class.ng-invalid]="categoryRequired || categoryTooMany">
          <mat-checkbox ngClass.xs="full-width" [checked]="selectedCategories.has(category.id)"
            *ngFor="let category of categories" (change)="changeCategory($event)" [value]="category.id" fxFlex="33">
            {{category.name}}</mat-checkbox>
        </div>
        <mat-error fxFlex="100" class="error-field" *ngIf="categoryRequired" translate>
          You must select at least one category
        </mat-error>
        <mat-error fxFlex="100" class="error-field" *ngIf="categoryTooMany">
          {{'You can not select more than' | translate }} {{environment.ui.showOnForm.maxCategories}} {{'categories' |
          translate}}
        </mat-error>
      </div>
    </div>

    <div class="mat-subheading-2">{{ 'Venue' | translate }}&nbsp;<span class="compulsory">*</span></div>
    <div class="hint" translate>Select or create a venue</div>
    <div class="margin-bottom" fxLayout="column">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label translate>Select a venue</mat-label>
        <mat-select required formControlName="venue" #venue (selectionChange)="onVenueChange($event)">
          <mat-option>
            <ngx-mat-select-search trim="blur" [placeholderLabel]="'Type the venue name' | translate"
              [formControl]="venueFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngIf="!newVenue && (filteredVenues | async)?.length == 0" [value]="null"><span translate>No
              venues found with this name</span></mat-option>
          <mat-option *ngFor="let venue of filteredVenues | async" [value]="venue.id">
            {{venue.name}}
          </mat-option>
          <mat-option class="filter-add-new" *ngIf="newVenue && newVenue.trim().length > 0"
            (onSelectionChange)="createVenue()" [value]="newVenue"><span>
              <mat-icon>add_circle</mat-icon> <span translate>Create new venue:</span>
            </span> {{newVenue}}</mat-option>
        </mat-select>
        <mat-hint *ngIf="eventForm.controls['venueObj'].value" align="end">
          {{eventForm.controls['venueObj'].value.address}}</mat-hint>
        <mat-error *ngIf="eventForm.hasError('required',['venue'])" translate>
          The venue is required
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline" *ngIf="venue.value">
        <mat-label translate>Venue notes</mat-label>
        <input trim="blur" type="text" #venueNotes matInput formControlName="venueNote"
          [maxlength]="maxCharsVenueNotes.toString()">
        <mat-hint align="end">{{'Guiding indications about the venue' | translate}} ({{venueNotes.value.length}} /
          {{maxCharsVenueNotes}})</mat-hint>
        <mat-error *ngIf="eventForm.hasError('maxlength',['venueNote'])">
          <span translate>Maximum length is</span> {{maxCharsVenueNotes}} <span translate>chars</span>
        </mat-error>
      </mat-form-field>
      <div fxLayout="column" [fxShow]="showMazemap" *ngIf="environment.ui.showOnForm.mazemap" fxLayout.xs="column"
        fxLayoutAlign="start start">
        <div class="mat-subheading-2" translate>Indoor map</div>
        <div class="hint" translate>Click on a room from the map</div>
        <app-mazemapeditor fxFill [onVenueChange]="eventForm.controls['venueObj'].valueChanges"
          [originalVenue]="originalEvent?.venueObj" [originalPoiID]="originalEvent?.mazeMapPoi"
          (onPoiChange)="onPoiChange($event)" (onLocationEvaluated)="onLocationEvaluated($event)"></app-mazemapeditor>
      </div>
      <div class="full-width">
        <mat-slide-toggle [checked]="eventForm.controls.mode.value == 'online'" (change)="onEventModeChange($event)">
          {{'This is an only online event' | translate}}</mat-slide-toggle>
      </div>
    </div>

    <div class="mat-subheading-2 margin-top">{{'Organizers' | translate }}&nbsp;<span class="compulsory">*</span></div>
    <div class="hint">{{'Select or create an organizer' | translate }}. {{'The first one will be considered the main
      organizer' | translate}}.</div>

    <div formArrayName="organizers" fxLayout="column" fxLayoutAlign="start start">
      <div class="full-width" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start"
        *ngFor="let organizerControl of eventForm.get('organizers')['controls']; let i=index;">
        <div [formGroupName]="i" class="full-width" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px"
          fxLayoutGap.xs="5px" fxLayoutAlign="start start">

          <mat-form-field fxFlex="grow" appearance="outline" class="organizer-field">
            <mat-label translate>Select an organizer</mat-label>
            <mat-select #organizer (selectionChange)="onOrganizerChange($event, i)" required
              formControlName="organizer">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Type the organizer name' | translate"
                  [formControl]="organizerFilterCtrl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="!newOrganizer && (filteredOrganizers | async)?.length == 0" [value]="null"><span
                  translate>No organizers found with this name</span></mat-option>
              <mat-option *ngFor="let organizer of filteredOrganizers | async" [value]="organizer.id">
                {{organizer.name}}
              </mat-option>
              <mat-option class="filter-add-new" *ngIf="newOrganizer && newOrganizer.trim().length > 0"
                (onSelectionChange)="createOrganizer(i)" [value]="newOrganizer"><span>
                  <mat-icon>add_circle</mat-icon> <span translate>Create new organizer:</span>
                </span> {{newOrganizer}}</mat-option>
            </mat-select>
            <mat-error *ngIf="eventForm.get('organizers')['controls'][i].hasError('required',['organizer'])" translate>
              You must select an organizer
            </mat-error>
          </mat-form-field>

          <div fxFlex="30" fxFlex.xs="auto" *ngIf="organizer.value" formGroupName="organizerObj" class="full-width">
            <mat-form-field appearance="outline">
              <mat-label translate>Email address</mat-label>
              <input trim="blur" type="email" matInput formControlName="email">
              <mat-error
                *ngIf="eventForm.get('organizers')['controls'][i].controls.organizerObj.get('email').hasError('email')"
                translate>
                This is not a valid email address
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="30" fxFlex.xs="auto" *ngIf="organizer.value" formGroupName="organizerObj" class="full-width">
            <mat-form-field appearance="outline">
              <mat-label translate>Telephone number</mat-label>
              <input trim="blur" type="tel" matInput formControlName="telephoneNumber">
            </mat-form-field>
          </div>
        </div>
        <button type="button" class="action-organizer" mat-icon-button *ngIf="i != 0" (click)="removeOrganizer(i)">
          <mat-icon aria-label="Remove organizer">remove_circle_outline</mat-icon>
        </button>
        <button type="button" class="action-organizer" mat-icon-button
          *ngIf="i == eventForm.get('organizers')['controls'].length - 1 && i < 2" (click)="addOrganizer()">
          <mat-icon aria-label="Add another organizer">add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
    <mat-error class="error-field" *ngIf="eventForm.hasError('allDistinct',['organizers'])" translate>
      All your organizers must be distinct
    </mat-error>



    <div class="mat-subheading-2">{{'Date' | translate }}&nbsp;<span class="compulsory">*</span></div>
    <div ngClass.xs="margin-bottom" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="start center">
      <mat-form-field fxFlex="150px" fxFlex.xs="65px" appearance="outline">
        <mat-label translate>Start date</mat-label>
        <input required (click)="picker.open()" matInput [matDatepicker]="picker" [min]="minDate"
          formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="eventForm.hasError('required',['startDate'])" translate>
          The start date is required
        </mat-error>
        <mat-error *ngIf="eventForm.hasError('matDatepickerMin',['startDate'])" translate>
          The start date can not be before today
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="isSuper" fxFlex.xs="65px" fxFlex="150px" appearance="outline">
        <mat-label translate>End date</mat-label>
        <input (click)="pickerEnd.open()" required matInput [matDatepicker]="pickerEnd"
          [min]="eventForm.controls['startDate']?.value" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
          <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
        <mat-error *ngIf="eventForm.hasError('required',['endDate'])" translate>
          The end date is required
        </mat-error>
        <mat-error *ngIf="eventForm.hasError('matDatepickerMin',['endDate'])" translate>
          The end date can not be before the start date
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!isSuper" fxFlex.xs="65px" fxFlex="150px" appearance="outline">
        <mat-label translate>Start time</mat-label>
        <input type="text" [placeholder]="'Start time' | translate" aria-label="Start time" matInput
          formControlName="startTime" [matAutocomplete]="startTimeAuto">
        <mat-autocomplete #startTimeAuto="matAutocomplete">
          <mat-option *ngFor="let hour of filteredStartHours | async" [value]="hour.value">
            <span [innerHTML]="hour.viewValue | highlight: hourToHighlight"></span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="eventForm.hasError('required',['startTime'])" translate>
          The start time is required
        </mat-error>
        <mat-error *ngIf="eventForm.hasError('pattern',['startTime'])" translate>
          Time format is HH:mm (14:30)
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!isSuper" fxFlex.xs="65px" fxFlex="150px" appearance="outline">
        <mat-label translate>End time</mat-label>
        <input type="text" [placeholder]="'End time' | translate" aria-label="End time" matInput
          formControlName="endTime" [matAutocomplete]="endTimeAuto">
        <mat-autocomplete #endTimeAuto="matAutocomplete">
          <mat-option *ngFor="let hour of filteredEndHours | async" [value]="hour.value">
            <span [innerHTML]="hour.viewValue | highlight: hourToHighlight"></span>
          </mat-option>
        </mat-autocomplete>
        <mat-hint align="end" *ngIf="durationStr">{{'Duration' | translate }}: {{durationStr}}</mat-hint>
        <mat-error *ngIf="eventForm.hasError('required',['endTime'])" translate>
          The end time is required
        </mat-error>
        <mat-error *ngIf="eventForm.hasError('pattern',['endTime'])" translate>
          Time format is HH:mm (14:30)
        </mat-error>
      </mat-form-field>

      <div *ngIf="!isSuper" class="add-repetitions" fxFlexAlign="start">
        <button type="button" [disabled]="eventRepetitions.length >= maxRepetitions"
          [matTooltip]="'Schedule multiple dates and times' | translate" class="full-width" mat-stroked-button
          (click)="addRepetitions()">
          <mat-icon>queue</mat-icon><span translate>Add repetitions</span>
        </button>
        <div align="end" *ngIf="eventRepetitions.length >= maxRepetitions" class="warning-repetitions">{{'Maximum number
          of repetitions is' | translate}} {{maxRepetitions}}</div>
      </div>
    </div>
    <div class="margin-bottom" *ngIf="eventRepetitions.length > 0">
      <div class="mat-subheading-2">{{ 'Repetitions' | translate }} {{ '(' + eventRepetitions.length + ')' }}</div>
      <div class="error-field" *ngIf="errorRepetitionsVenue">{{'All the repetitions must have a venue' | translate}}
      </div>
      <div class="repetitions-container" [ngClass]="errorRepetitionsVenue ? 'ng-invalid' : ''" fxLayout="column"
        fxLayoutGap="20px">
        <app-event-repetition *ngFor="let eventRepetition of eventRepetitions; let i = index"
          (removed)="removeRepetition(i)" (updated)="updateRepetition(i, $event)" [allVenues]="venues" [registrationEnabled]="eventForm?.controls?.registrationEnabled?.value"
          [eventRepetition]="eventRepetition" [minDate]="eventForm.value?.startDate"></app-event-repetition>
      </div>
    </div>

    <div class="mat-subheading-2" *ngIf="environment.vmFestEnabled" translate>VM-Fest 2025</div>
    <div fxLayout="row wrap" *ngIf="environment.vmFestEnabled" fxLayoutAlign="start center" class="margin-bottom">
      <mat-slide-toggle formControlName="eventVMFest" fxFlex="100">{{'This event is a part of VM-Fest 2025 (26th February - 9th March)' | translate}}
      </mat-slide-toggle>
    </div>

    <div class="mat-subheading-2">{{'Image' | translate}}&nbsp;<span class="compulsory">*</span></div>
    <div
      [ngClass]="eventForm.controls.images.touched && (eventForm.hasError('required',['images']) || eventForm.hasError('maxLength',['images'])) ? 'ng-invalid' : ''">
    </div>
    <div fxLayout="row wrap" fxLayout.xs="column" class="image-section margin-bottom" fxLayoutAlign="start center">
      <div class="hint">{{'Upload at least one picture and a maximum of' | translate }} {{maxNumImages}}. {{'Optimal
        dimensions: 800x450px' | translate }}. <a class="blue" target="_blank" href="/guidelines-pictures">{{'You can
          read
          the guidelines for pictures here' | translate}}</a> <img src="assets/icons/open_in_new.svg"
          class="external-link" /></div>
      <ng-container class="full-width" formArrayName="images">
        <div class="full-width margin-bottom" *ngFor="let image of images.controls; index as i; last as isLast;">
          <ng-container class="full-width" [formGroupName]="i">
            <app-image-uploader class="full-width" (onImageRemoved)="onImageRemoved(i)" (onMoveUp)="moveUp(i)"
              (onMoveDown)="moveDown(i)" [index]="i" [isLast]="isLast" [imageForm]="image"
              [imageAsDataURL]="imageAsDataURL"></app-image-uploader>
          </ng-container>
        </div>
        <div class="upload-image" *ngIf="images.length < maxNumImages" fxLayout.xs="column" fxLayout="row"
          [fxLayoutAlign]="environment.ui.showOnForm.importImageFromURL ? 'space-evenly center' : 'center center'">
          <div [ngClass]="environment.ui.showOnForm.importImageFromURL ? '' : 'text-center'"
            [fxFlex]="environment.ui.showOnForm.importImageFromURL ? '220px' : '100'" fxLayout.xs="column"
            fxFlex.xs="auto" fxFlexOrder="1">
            <button mat-stroked-button type="button" (click)="uploadImage()">
              <mat-icon>upload_file</mat-icon>
              <span>{{'Upload from computer' | translate }}</span>
            </button>
          </div>
          <div *ngIf="environment.ui.showOnForm.importImageFromURL" fxHide.xs="true" fxFlexOrder="2" fxFlex="nogrow">
            <mat-divider [vertical]="true"></mat-divider>
          </div>
          <div *ngIf="environment.ui.showOnForm.importImageFromURL" fxFlex="50px" class="margin-top" fxShow.xs="true"
            fxFlexOrder.xs="2" fxShow.gt-sm="false">
            - <span translate>OR</span> -
          </div>
          <div *ngIf="environment.ui.showOnForm.importImageFromURL" fxFlex="220px" fxLayout="column" fxFlex.xs="auto"
            fxFlexOrder="3" fxLayoutGap="10px">
            <input [placeholder]="'Introduce image URL' | translate" class="remote-image" type="text" #imageImportURL />
            <button class="full-width" mat-stroked-button type="button" (click)="importImageFromURL(imageImportURL)">
              <mat-icon>cloud_download</mat-icon>
              <span>{{'Import from URL' | translate }}</span>
            </button>
          </div>
        </div>
        <div class="error-field"
          *ngIf="eventForm.controls.images.touched && (eventForm.hasError('required',['images']) || eventForm.hasError('maxLength',['images']))">
          {{'You must upload between' | translate }} 1 {{'and' | translate }} {{maxNumImages}} {{'pictures' | translate
          }}
        </div>
      </ng-container>
    </div>


    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <mat-panel-title>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <mat-icon>description</mat-icon>
                  <div>{{'Details' | translate}}</div>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                {{'Tags, event relations and age limits' | translate}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <div *ngIf="environment.ui.showOnForm.summary" class="mat-subheading-2" translate>Summary</div>
          <div *ngIf="environment.ui.showOnForm.summary" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
            fxLayoutAlign="space-between start">
            <mat-form-field appearance="outline">
              <mat-label translate>Event summary in Norwegian</mat-label>
              <textarea cdkTextareaAutosize cdkAutosizeMaxRows="6" cdkAutosizeMinRows="2"
                class="language-field norwegian" #summary_nb maxlength="maxCharsSummary.toString()" matInput
                formControlName="summary_nb"></textarea>
              <mat-hint align="end">{{summary_nb.value.length}} / {{maxCharsSummary}}</mat-hint>
              <mat-error *ngIf="eventForm.hasError('maxlength',['summary_nb'])">
                {{'Maximum length is' | translate}} {{maxCharsSummary}} {{'chars' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label translate>Event summary in English</mat-label>
              <textarea cdkTextareaAutosize cdkAutosizeMaxRows="6" cdkAutosizeMinRows="2" class="language-field english"
                #summary_en maxlength="maxCharsSummary.toString()" matInput formControlName="summary_en"></textarea>
              <mat-hint align="end">{{summary_en.value.length}} / {{maxCharsSummary}}</mat-hint>
              <mat-error *ngIf="eventForm.hasError('maxlength',['summary_en'])">
                {{'Maximum length is' | translate}} {{maxCharsSummary}} {{'chars' | translate}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="mat-subheading-2"><span translate>Tags</span></div>
          <mat-form-field fxFill appearance="outline">
            <mat-chip-list #tags aria-label="Event tags">
              <mat-chip removable (removed)="removeTag(tag)" *ngFor="let tag of eventTags">
                {{tag}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input [placeholder]="'Add a tag...' | translate" [matChipInputFor]="tags"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur
                (matChipInputTokenEnd)="addTag($event)">
            </mat-chip-list>
            <mat-hint align="end">{{'Introduce key words related to this event. This will help people find the event.
              Maximum' | translate}} {{environment.ui.showOnForm.maxTags}}.</mat-hint>
          </mat-form-field>

          <div class="mat-subheading-2" *ngIf="environment.ui.showOnForm.activityType" #activityTypeContainer><span
              translate>Activity type</span>&nbsp;<span class="compulsory">*</span></div>
          <div fxLayout="row wrap" *ngIf="environment.ui.showOnForm.activityType" fxLayout.xs="column"
            fxLayoutAlign="start center" class="margin-bottom">
            <div fxFlex="100" class="hint" ngClass.xs="full-width" align="start">{{'Select at least one activity type
              and
              a
              maximum of' | translate}} {{environment.ui.showOnForm.maxActivityType}}.</div>
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" ngClass.xs="full-width"
              class="container-checkbox" [class.ng-invalid]="activityTypeRequired || activityTypeTooMany">
              <mat-checkbox *ngFor="let activityType of allActivityTypes" ngClass.xs="full-width"
                [checked]="selectedActivityTypes.has(activityType.id)" (change)="changeActivityType($event)"
                [value]="activityType.id" fxFlex="33">
                {{activityType.name}}</mat-checkbox>
            </div>
            <mat-error fxFlex="100" class="error-field" *ngIf="activityTypeRequired" translate>
              You must select at least one activity type
            </mat-error>
            <mat-error fxFlex="100" class="error-field" *ngIf="activityTypeTooMany">
              {{'You can not select more than' | translate}} {{environment.ui.showOnForm.maxActivityType}} {{'activity
              types' | translate}}
            </mat-error>
          </div>

          <div class="mat-subheading-2" translate>Event relationship</div>
          <div class="hint" translate>Select if your event is stand alone or has a relation to another event</div>
          <div class="margin-bottom" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
            <mat-radio-group fxLayout="column" fxLayoutGap="10px" fxFlex="100" ngClass.xs="full-width"
              (change)="onEventTypeChange($event)" formControlName="type">
              <mat-radio-button value="orphan"><span translate>This is a stand alone event</span></mat-radio-button>
              <mat-radio-button value="super-event"><span translate>This event contains other events within it</span>
              </mat-radio-button>
              <mat-radio-button value="has-super"><span translate>This event belongs to a bigger event (select which one
                  below)</span>
              </mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="hasSuper" appearance="outline">
              <mat-label translate>Select the bigger event</mat-label>
              <mat-select formControlName="super_event" class="full-width">
                <mat-option *ngFor="let superEvent of superEvents" [value]="superEvent.id">
                  <img *ngIf="superEvent.imageURL" class="thumbnail-festival" [src]="superEvent.imageURL">
                  {{superEvent.title_nb}}
                </mat-option>
              </mat-select>
              <mat-hint translate align="end">Select the event to which this event belongs</mat-hint>
            </mat-form-field>
          </div>

          <div class="mat-subheading-2" translate>Age restriction</div>
          <div class="hint" translate>Select if there is any age restriction for this event</div>
          <div fxLayout="row wrap" fxLayout.xs="column" class="age-restrictions-container" fxLayoutAlign="start center">
            <mat-radio-group fxFlex="100" ngClass.xs="full-width" fxLayout.xs="column" fxLayoutGap="15px"
              class="age-restrictions" (change)="onAgeRestrictionChange($event)" formControlName="ageRestriction">
              <mat-radio-button value="no-restriction"><span translate>No restriction</span></mat-radio-button>
              <mat-radio-button value="minimum-age"><span translate>Minimum age limit</span></mat-radio-button>
              <mat-radio-button value="maximum-age"><span translate>Maximum age limit</span></mat-radio-button>
            </mat-radio-group>
            <div class="age-limit" fxFlex="180px" fxFlexAlign.xs="start" fxFlex.xs="auto"
              *ngIf="eventForm.controls['ageRestriction'].value == 'minimum-age'">
              <mat-form-field>
                <mat-label translate>Minimum age</mat-label>
                <span matSuffix translate>&nbsp; years</span>
                <input trim="blur" required min="1" max="99" formControlName="minimumAge" type="number" matInput>
                <mat-error *ngIf="eventForm.hasError('min',['minimumAge']) || eventForm.hasError('max',['minimumAge'])"
                  translate>
                  Age must be a number between 1 and 99
                </mat-error>
                <mat-error *ngIf="eventForm.hasError('required',['minimumAge'])" translate>
                  You must set the minimum age
                </mat-error>
              </mat-form-field>
            </div>
            <div class="age-limit" fxFlex="180px" fxFlexAlign.xs="start" fxFlex.xs="auto"
              *ngIf="eventForm.controls['ageRestriction'].value == 'maximum-age'">
              <mat-form-field>
                <mat-label translate>Maximum age</mat-label>
                <span matSuffix translate>&nbsp; years</span>
                <input trim="blur" required min="1" max="99" formControlName="maximumAge" type="number" matInput>
                <mat-error *ngIf="eventForm.hasError('min',['maximumAge']) || eventForm.hasError('max',['maximumAge'])"
                  translate>
                  Age must be a number between 1 and 99
                </mat-error>
                <mat-error *ngIf="eventForm.hasError('required',['maximumAge'])" translate>
                  You must set the maximum age
                </mat-error>
              </mat-form-field>
            </div>
          </div>


        </mat-expansion-panel>


        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <mat-panel-title>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <mat-icon>sync_alt</mat-icon>
                  <div>{{'Status and synchronizations' | translate}}</div>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                {{'Synchronizations, status and others' | translate}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <div class="mat-subheading-2" translate>Event Status</div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="margin-bottom">
            <div fxFlex="100" class="hint" align="start" translate>Please, check if your event is cancelled or sold out.
            </div>
            <mat-slide-toggle formControlName="eventCancelled" fxFlex="100"><span translate>My event is cancelled</span>
            </mat-slide-toggle>
            <mat-slide-toggle formControlName="eventSoldOut" fxFlex="100"><span translate>My event is sold out</span>
            </mat-slide-toggle>
          </div>

          <div class="mat-subheading-2" *ngIf="environment.synchro.length > 0" translate>Synchronizations</div>
          <div fxLayout="row wrap" *ngIf="environment.synchro.length > 0" fxLayoutAlign="start center"
            class="margin-bottom">
            <mat-slide-toggle [ngClass]="!isValidForSynchronizing[synchro.destination] ? 'toggle-hint' : ''"
              *ngFor="let synchro of environment.synchro" [name]="synchro.destination"
              [disabled]="!isValidForSynchronizing[synchro.destination]" [checked]="shallBeSynchronized(synchro)"
              (change)="changeSynchro($event)" fxFlex="100">
              {{'Synchronize it with' | translate}} {{synchro.name}} <span
                *ngIf="shallBeSynchronized(synchro) && synchroLink(synchro.destination)">
                (<a class="blue" [href]="synchroLink(synchro.destination)" target="_blank">{{'show event' |
                  translate}}</a>)</span>
              <div *ngIf="!isValidForSynchronizing[synchro.destination] && synchro.type === 'hsk'" class="hint">
                {{'There
                is
                an
                event with the same title in' | translate }} {{synchro.name}}</div>
            </mat-slide-toggle>
          </div>

          <div class="mat-subheading-2"
            *ngIf="environment.ui.showOnForm.featuredEvent && (!environment.ui.showOnForm.featuredEventOnlyAdmins || currentUserInfo?.roles?.admin)"
            translate>Other information</div>
          <div fxLayout="row wrap"
            *ngIf="environment.ui.showOnForm.featuredEvent && (!environment.ui.showOnForm.featuredEventOnlyAdmins || currentUserInfo?.roles?.admin)"
            fxLayoutAlign="start center" class="margin-bottom">
            <mat-slide-toggle formControlName="isFeaturedEvent" fxFlex="100">
              <span translate>This is a featured event</span>
            </mat-slide-toggle>
            <mat-slide-toggle formControlName="isFeaturedVMFestEvent" fxFlex="100">
              <span translate>This is a featured event in VM-Fest</span>
            </mat-slide-toggle>
          </div>



          <div class="mat-subheading-2"
            *ngIf="environment.ui.showOnForm.eatTogether || environment.ui.showOnForm.fieldEvent" translate>Other
            information</div>
          <div fxLayout="row wrap" *ngIf="environment.ui.showOnForm.eatTogether" fxLayoutAlign="start center"
            class="margin-bottom">
            <mat-slide-toggle formControlName="eatTogether" fxFlex="100">
              <span translate>This is an "Eat together" event</span>
            </mat-slide-toggle>
          </div>

          <div fxLayout="row wrap" *ngIf="environment.ui.showOnForm.fieldEvent" fxLayoutAlign="start center"
            class="margin-bottom">
            <mat-slide-toggle formControlName="fieldEvent" fxFlex="100">
              <span translate>This is a food branch event</span>
              <div class="hint" translate>This event is targeted to professionals of the food industry</div>
            </mat-slide-toggle>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header [ngClass]="{'errorPanel': eventFormRef.submitted && anyPriceEmpty()}">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <mat-panel-title>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <mat-icon>local_activity</mat-icon>
                  <div>{{'Tickets' | translate}}</div>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                {{'Ticket prices and other options' | translate}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <mat-slide-toggle class="margin-top" formControlName="noTicketsInfo" (change)="onPricesInfoChange($event)">{{'Tickets information not available' | translate}}
          </mat-slide-toggle>
          <div *ngIf="!eventForm.controls.noTicketsInfo.value" class="mat-subheading-2" translate>Prices</div>
          <div *ngIf="!eventForm.controls.noTicketsInfo.value" class="hint" translate>{{'Indicate the prices for your event' | translate}}. <a class="blue" (click)="addTicketType()">{{'Add another ticket type' | translate}}.</a></div>
          <div *ngIf="!eventForm.controls.noTicketsInfo.value" fxLayout="column" class="prices-container"
            fxLayoutAlign="start start">
            <div class="full-width" fxLayoutGap="25px" fxLayout="column" fxLayoutAlign="start start">
              <div formArrayName="prices"
                *ngFor="let priceControl of eventForm.get('prices')['controls']; let i=index;">
                <div [formGroupName]="i" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="5px"
                  fxLayoutAlign="start start">

                  <mat-form-field fxFlex="40" appearance="outline" class="price-type-field">
                    <mat-label>{{'Type' | translate}}</mat-label>
                    <mat-select required [placeholder]="'Type' | translate" formControlName="type" (selectionChange)="setTicketTypeNames($event, i)">
                      <mat-option *ngFor="let ticketType of allTicketTypes" [value]="ticketType.id">{{ticketType.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="eventForm.get('prices')['controls'][i].hasError('required',['type'])" translate>
                      You must select a price type
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" appearance="outline">
                    <mat-label translate>Price in NOK</mat-label>
                    <input type="number" required matInput formControlName="price" (change)="evaluateIfAllTicketsAreFree()"
                      min="0">
                    <mat-error *ngIf="eventForm.get('prices')['controls'][i].get('price').hasError('min')" translate>
                      Price must be a non negative number
                    </mat-error>
                    <mat-error *ngIf="eventForm.get('prices')['controls'][i].get('price').hasError('required')"
                      translate>
                      Price is required. Write 0 if it is gratis.
                    </mat-error>
                  </mat-form-field>

                  <div fxLayout="row" style="height: 70px;" fxLayoutAlign="start center">
                    <button type="button" class="action-price" mat-icon-button *ngIf="i != 0" (click)="removePrice(i)">
                      <mat-icon aria-label="Remove price">remove_circle_outline</mat-icon>
                    </button>

                    <button type="button" class="action-price" mat-icon-button (click)="addPrice()">
                      <mat-icon aria-label="Add another price">add_circle_outline</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <mat-form-field appearance="outline" class="full-width"
                *ngIf="!eventForm.controls.noTicketsInfo.value && !eventForm.controls.registrationEnabled.value">
                <mat-label>{{ allTicketsAreFree ? ('Registration URL' |
                  translate): ('Tickets URL' | translate) }} </mat-label>
                <input trim="blur" matInput tyle="url" formControlName="ticketsURL">
                <mat-hint align="end" translate>Please, insert the URL for registration or tickets sale</mat-hint>
                <mat-error *ngIf="eventForm.hasError('pattern',['ticketsURL'])" translate>
                  Tickets URL must be a valid URL (i.e. https://example.com)
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="environment.ui.showOnForm.rsvp && !eventForm.controls.noTicketsInfo.value" fxLayout="column">

            <mat-slide-toggle class="margin-bottom" formControlName="registrationEnabled">{{'Enable attendants
              registration for this event' | translate}}</mat-slide-toggle>

            <div *ngIf="eventForm.controls.registrationEnabled.value" fxLayout="column"
              fxLayoutAlign="center space-around" fxLayoutGap="40px">

              <div fxLayout="column" fxLayoutGap="10px">
                <div translate>Availability</div>
                <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px"
                  fxLayoutAlign.xs="start start" fxLayout.xs="column" class="full-width">
                  <mat-form-field fxFlex appearance="outline">
                    <mat-label>{{'Available tickets' | translate}}</mat-label>
                    <input type="number" matInput formControlName="availableTickets" min="0" required>
                    <mat-hint>{{'Number of available tickets' | translate}}</mat-hint>
                    <mat-error *ngIf="eventForm.get('availableTickets').hasError('required')">
                      {{'The number of available tickets is required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="eventForm.get('availableTickets').hasError('min')">
                      {{'The number of available tickets must be a non negative number' | translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex appearance="outline">
                    <mat-label translate>From date</mat-label>
                    <input required (click)="pickerTicketsFromDate.open()" matInput
                      [matDatepicker]="pickerTicketsFromDate" formControlName="ticketsFromDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerTicketsFromDate">
                      <mat-icon matDatepickerToggleIcon>event</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerTicketsFromDate></mat-datepicker>
                    <mat-hint>{{'Booking from this date' | translate}}</mat-hint>
                    <mat-error *ngIf="eventForm.hasError('required',['ticketsFromDate'])" translate>
                      The available date is required
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex appearance="outline">
                    <mat-label translate>From time</mat-label>
                    <input type="text" [placeholder]="'From time' | translate" aria-label="From time" matInput
                      formControlName="ticketsFromTime" [matAutocomplete]="fromTimeAuto">
                    <mat-autocomplete #fromTimeAuto="matAutocomplete">
                      <mat-option *ngFor="let hour of filteredStartHours | async" [value]="hour.value">
                        <span [innerHTML]="hour.viewValue | highlight: hourToHighlight"></span>
                      </mat-option>
                    </mat-autocomplete>
                    <mat-hint>{{'Booking from this time' | translate}}</mat-hint>
                    <mat-error *ngIf="eventForm.hasError('required',['ticketsFromTime'])" translate>
                      The available time is required
                    </mat-error>
                    <mat-error *ngIf="eventForm.hasError('pattern',['ticketsFromTime'])" translate>
                      Time format is HH:mm (14:30)
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="full-width" *ngIf="!allTicketsAreFree">
                <div translate>Payment method</div>
                <div class="hint" translate>Select the payment method for the attendants</div>
                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center">
                  <mat-radio-group fxFlex="100" fxLayoutGap="15px" ngClass.xs="full-width" class="prices"
                    formControlName="paymentMethod">
                    <mat-radio-button value="door">{{'At the door' | translate}}</mat-radio-button>
                    <mat-radio-button value="invoice">{{'Invoice' | translate}}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="full-width" fxLayout="column" fxLayoutGap="10px" *ngIf="!allTicketsAreFree">
                <div translate>Cancellation period</div>
                <div fxLayout="row" class="full-width">
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>{{'Cancellation period in days' | translate}}</mat-label>
                    <input type="number" matInput #cancellationPeriod formControlName="cancellationPeriod" min="0">
                    <mat-hint *ngIf="cancellationPeriod.value > 0">{{'Tickets can be cancelled' | translate}}
                      {{cancellationPeriod.value}} {{'days before the event starts' | translate}}</mat-hint>
                    <mat-hint *ngIf="cancellationPeriod.value == 0">{{'Tickets can be cancelled at any time' |
                      translate}}</mat-hint>
                    <mat-error *ngIf="eventForm.get('cancellationPeriod').hasError('required')">
                      {{'The cancellation period is required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="eventForm.get('cancellationPeriod').hasError('min')">
                      {{'The number of days before the event starts must be a non negative number' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

        </mat-expansion-panel>


        <mat-expansion-panel>

          <mat-expansion-panel-header>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <mat-panel-title>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <mat-icon>image</mat-icon>
                  <div>{{'Media and links' | translate}}</div>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                {{'Video and external links' | translate}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>


          <div class="mat-subheading-2">{{'Embedded video' | translate}}</div>
          <div class="hint" translate>Show an embedded video in your event details</div>
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start start">
            <mat-form-field fxFill appearance="outline">
              <mat-label translate>Embedded Video URL</mat-label>
              <input autocomplete="off" trim="blur" matInput formControlName="embeddedVideoURL">
              <mat-hint align="end" translate>Paste a video URL from Panopto, Youtube or Vimeo</mat-hint>
              <mat-error *ngIf="eventForm.hasError('invalidPanoptoURL',['embeddedVideoURL'])">
                <div translate>URL must be a valid Panopto video link</div>
                <div>https://ntnu.cloud.panopto.eu/Panopto/Pages/Embed.aspx?id=8c4e8691-fba1-4cbe-818d-ac1000b9dfa5
                </div>
              </mat-error>
              <mat-error *ngIf="eventForm.hasError('invalidYoutubeURL',['embeddedVideoURL'])">
                <div translate>URL must be a valid Youtube video link</div>
                <div>https://www.youtube.com/watch?v=x05kBzPOR60</div>
              </mat-error>
              <mat-error *ngIf="eventForm.hasError('invalidVimeoURL',['embeddedVideoURL'])">
                <div translate>URL must be a valid Vimeo video link</div>
                <div>https://vimeo.com/53722593</div>
              </mat-error>

            </mat-form-field>
          </div>


          <div class="mat-subheading-2" translate>Links</div>
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start start">
            <mat-form-field fxFill appearance="outline">
              <mat-label translate>Streaming URL</mat-label>
              <input trim="blur" matInput tyle="url" formControlName="streamingURL">
              <mat-hint align="end" translate>Please, insert the streaming URL</mat-hint>
              <mat-error *ngIf="eventForm.hasError('pattern',['streamingURL'])" translate>
                The streaming URL must be a valid URL (i.e. https://example.com)
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFill appearance="outline">
              <mat-label translate>More info URL</mat-label>
              <input trim="blur" matInput tyle="url" formControlName="moreInfoURL">
              <mat-hint align="end" translate>Please, insert the URL of your event</mat-hint>
              <mat-error *ngIf="eventForm.hasError('pattern',['moreInfoURL'])" translate>
                More info URL must be a valid URL (i.e. https://example.com)
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFill appearance="outline">
              <mat-label translate>Facebook event URL</mat-label>
              <input trim="blur" matInput tyle="url" formControlName="facebookURL">
              <mat-hint align="end" translate>Please, insert the facebook event URL</mat-hint>
              <mat-error *ngIf="eventForm.hasError('facebookEventPattern',['facebookURL'])" translate>
                Facebook event URL must be a valid facebook event URL (i.e.
                https://www.facebook.com/events/249886985791185/)
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFill appearance="outline">
              <mat-label translate>Videos URL</mat-label>
              <input trim="blur" matInput tyle="url" formControlName="videosURL">
              <mat-hint align="end" translate>Please, insert a URL with videos of the event.</mat-hint>
              <mat-error *ngIf="eventForm.hasError('pattern',['videosURL'])" translate>
                Videos URL must be a valid URL (i.e. https://example.com)
              </mat-error>
            </mat-form-field>
          </div>

        </mat-expansion-panel>



        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <mat-panel-title>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <mat-icon>lock</mat-icon>
                  <div>{{'Publishing and permissions' | translate}}</div>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                {{'Visibility, publishing and permissions' | translate}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>


          <div class="mat-subheading-2" translate>Publishing options</div>
          <div class="hint" translate>Select the publishing options of your event</div>
          <div class="margin-bottom" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
            <mat-radio-group fxLayout="column" fxLayoutGap="10px" fxFlex="100" ngClass.xs="full-width"
              (change)="onPublishingOptionChange($event)" formControlName="publishingOption">
              <mat-radio-button value="now">{{ 'Public' | translate }}</mat-radio-button>
              <mat-radio-button value="scheduled">{{ 'Schedule' | translate }}</mat-radio-button>
              <mat-radio-button *ngIf="environment.ui.showOnForm.unlisted" value="unlisted">{{ 'Unlisted (visible with
                the
                link)' | translate }}</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="eventForm.controls['publishingOption'].value == 'scheduled'" fxLayout="row" fxLayoutGap="10px"
              fxLayoutAlign="start start">
              <mat-form-field fxFlex="200px" fxFlex.xs="65px" appearance="outline">
                <mat-label translate>Publishing date</mat-label>
                <input required (click)="pickerPublishingDate.open()" matInput [matDatepicker]="pickerPublishingDate"
                  [min]="minPublishingDate" formControlName="publishingDate">
                <mat-datepicker-toggle matSuffix [for]="pickerPublishingDate">
                  <mat-icon matDatepickerToggleIcon>event</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #pickerPublishingDate></mat-datepicker>
                <mat-error *ngIf="eventForm.hasError('required',['publishingDate'])" translate>
                  The publishing date is required for the schedule option
                </mat-error>
                <mat-error *ngIf="eventForm.hasError('matDatepickerMin',['publishingDate'])" translate>
                  The publishing date can not be before today
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="65px" fxFlex="150px" appearance="outline">
                <mat-label translate>Publishing time</mat-label>
                <mat-select [placeholder]="'Publishing time' | translate" aria-label="Publishing time"
                  formControlName="publishingTime">
                  <mat-option *ngFor="let hour of hours" [value]="hour.value">
                    {{hour.viewValue}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="eventForm.hasError('required',['publishingTime'])" translate>
                  The publishing time is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>


          <div class="mat-subheading-2 margin-top" translate>Edit permissions</div>
          <div class="hint" translate>Select who has rights to edit this event</div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
            <mat-form-field fxFill appearance="outline" class="team-field">
              <mat-label translate>Editable by</mat-label>
              <mat-select required formControlName="editableBy">
                <mat-option value="only-author">
                  {{ 'Only me' | translate}}
                </mat-option>
                <mat-optgroup [label]="'Teams' | translate">
                  <mat-option *ngFor="let team of (teams$ | async)" [value]="team.id">
                    {{team.name}} ({{team.users.join(", ")}})
                  </mat-option>
                  <mat-option class="new-team" (onSelectionChange)="onCreateTeamSelected($event)">
                    <mat-icon>add_circle</mat-icon> {{'Create new team' | translate}}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-hint align="end" translate>Select or create a team</mat-hint>
              <mat-error *ngIf="eventForm.hasError('required',['editableBy'])" translate>
                Select who has the rights to edit this event
              </mat-error>
            </mat-form-field>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <div class="mat-subheading-2 margin-top">{{'Publishing rules' | translate }}&nbsp;<span class="compulsory">*</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <mat-slide-toggle formControlName="publishingRules" required class="publishing-rules"><span>{{'I
          assure that this event follows' | translate }}</span> <a target="_blank" class="blue"
          routerLink="/publishing-rules"><span class="wrap-mat-checkbox-label">{{'the publishing rules' |
            translate}}</span></a>
        <span class="wrap-mat-checkbox-label">{{'required by this website' | translate}}</span>
      </mat-slide-toggle>

      <mat-error
        *ngIf="eventForm.controls.publishingRules.touched && eventForm.hasError('required',['publishingRules'])"
        class="error-field" translate>
        You must accept the publishing rules
      </mat-error>
    </div>

    <div class="add-button-container" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center center"
      *ngIf="((eventForm.controls.publishingOption.valueChanges | async) || eventForm.controls.publishingOption.value) as publishingOption">
      <button mat-raised-button routerLink="/my-events" type="button"><span translate>Cancel</span></button>
      <button mat-raised-button color="primary"
        *ngIf="!isEdit && publishingOption != 'scheduled' && publishingOption != 'unlisted'" type="submit"><span
          translate>Publish event</span></button>
      <button mat-raised-button color="primary" *ngIf="!isEdit && publishingOption == 'scheduled'" type="submit"><span
          translate>Schedule event</span></button>
      <button mat-raised-button color="primary" *ngIf="!isEdit && publishingOption == 'unlisted'" type="submit"><span
          translate>Publish unlisted event</span></button>
      <button mat-raised-button color="primary" *ngIf="isEdit" type="submit"><span translate>Save event</span></button>
      <button mat-raised-button color="warn" *ngIf="isEdit" (click)="deleteEvent()"><span translate>Delete</span>
      </button>
    </div>
  </form>
</div>