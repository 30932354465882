<div class="classic" *ngIf="environment.theme == 'classic'">
  <div class="navigation-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <div class="navigation-toolbar full-width" fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between center">
      <a class="all-events color-primary" (click)="goFrontPage()" mat-raised-button>
        <mat-icon aria-label="Back to all events">navigate_before</mat-icon><span fxShow="true" fxShow.xs="false"
          translate>Events</span><span fxShow="false" fxShow.xs="true" translate>All</span>
      </a>
      <a routerLink="/add-an-event" class="add-event-button" color="primary" mat-raised-button>
        <img class="icon-button" src="assets/icons/add_circle.svg" /><span fxShow="true" fxShow.xs="false" translate>Add
          an event</span><span fxShow="false" fxShow.xs="true" translate>Event</span>
      </a>
    </div>
  </div>
  <div class="wrapper" fxLayoutGap="0px">
    <div *ngIf="isLoading" class="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
      <div *ngIf="event == null" class="event-single-container mat-elevation-z1 not-found" translate>The event URL does
        not exist or it has changed</div>
      <div *ngIf="event != null" class="event-single-container mat-elevation-z1">
        <app-my-json-ld [itemLD]="productLD"></app-my-json-ld>
        <app-image-carrousel *ngIf="event != null" [images]="event.images">
        </app-image-carrousel>
        <div class="event-single-body" fxLayout="column" fxLayoutGap="30px">
          <div class="warning" *ngIf="datesWithinWarning">
            <div class="warning-title">{{'Warning' | translate}}:</div>
            <div [innerHTML]="warningContent"></div>
          </div>
          <div fxLayout="row"
            *ngIf="(!event.repetitions || event.repetitions.length == 0) && (event.eventCancelled || event.eventSoldOut || hasPassed(event))"
            fxLayoutAlign="start start" class="full-width margin-bottom">
            <div fxFlex="100" class="event-warning passed" *ngIf="hasPassed(event)" translate>This event has passed
            </div>
            <div fxFlex="100" class="event-warning cancelled" *ngIf="event.eventCancelled" translate>cancelled_event
            </div>
            <div fxFlex="100" class="event-warning soldout" *ngIf="event.eventSoldOut">
              <div>{{'Sold out' | translate}}</div>
              <div *ngIf="environment.ui.showOnForm.rsvp" class="small-notice">{{'Waiting list available' | translate}}
              </div>
            </div>
          </div>
          <a class="jumbo-tickets" *ngIf="environment.ui.showOnForm.rsvp && event?.registrationEnabled"
            [routerLink]="['./']" fragment="book-tickets">
            {{'Order, cancel or change number of tickets' | translate}}
          </a>
          <div fxLayout="row" fxLayout.xs="column" class="full-width margin-bottom" fxLayoutAlign.xs="start start"
            fxLayoutGap="20px" fxLayoutGap.xs="30px" fxLayoutAlign="start end">
            <div fxFlexOrder="1" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign.xs="start start" fxFlexOrder.xs="2"
              fxFlex="grow" fxFlex.xs="100">
              <div class="date third-color" *ngIf="event.repetitions?.length == 0">{{displayDate(event.startDate) +
                (event.type == 'super-event' &&
                day(event.startDate) !=
                day(event.endDate) ? ' - ' + displayDate(event.endDate) : '')}} <span
                  *ngIf="event.type != 'super-event' && event.startTime">{{displayTime(event.startDate) +
                  (event.duration
                  > 0 ? ' - ' + displayTime(event.endDate) : '')}}</span>
              </div>
              <h1 class="title">{{utilsService.getTitle(event)}}</h1>

              <div class="repetitions full-width" fxLayout="column" fxLayoutGap="10px"
                *ngIf="isBrowser && event.repetitions.length > 0" fxLayoutAlign="start center">
                <div fxFlex="80px" fxFill [fxHide]="i > 9 && !seeAllRepetitions"
                  *ngFor="let repetition of event.repetitions; let i = index" class="repetition" fxLayout="column"
                  fxLayoutAlign="space-between start" fxLayoutGap="5px">
                  <div fxLayout="row" class="full-width" fxLayoutAlign="space-between center">
                    <div class="date-time">{{displayDateRepetition(repetition.startDate)}}</div>
                    <div class="event-warning cancelled" *ngIf="repetition.eventCancelled" translate>cancelled_event
                    </div>
                    <div class="event-warning soldout" *ngIf="repetition.eventSoldOut" translate>Sold out</div>
                  </div>
                  <div fxLayout="row" class="full-width" fxLayoutAlign="space-between center">
                    <div class="mode" *ngIf="repetition.mode == 'offline' && repetition.venue?.name" fxLayout="row"
                      fxLayoutAlign="start center">{{(repetition.mode == 'offline' && repetition.venue?.name) ?
                      repetition.venue?.name : ('Online' | translate)}}</div>
                    <div *ngIf="environment.ui.showOnForm.rsvp && event?.registrationEnabled && !hasPassed(event)">
                      <a [routerLink]="['./']" class="book-tickets" fragment="book-tickets">{{'Tickets' |
                        translate}}</a>
                    </div>
                  </div>
                </div>
                <a fxHide [fxShow]="event.repetitions.length > 10" (click)="seeAllRepetitions = !seeAllRepetitions"
                  fxFlex="80px" fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center center" class="repetition">
                  <span class="date-time">{{seeAllRepetitions ? ('Hide' | translate) : '+' + (event.repetitions.length -
                    10)}}</span>
                </a>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap.gt-sm="40px"
            class="full-width margin-bottom">
            <div fxFlex="auto">
              <div class="subtitle">{{'Details' | translate}}</div>
              <div class="mat-body summary" *ngIf="utilsService.getSummary(event) as summary">{{summary}}</div>
              <div class="mat-body description" *ngIf="utilsService.getDesc(event) as description"
                [innerHTML]="description"></div>
              <div class="embedded-video-container" *ngIf="videoSRC">
                <iframe style="height: 280px; width: 100%;" [src]="videoSRC" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
              <div class="tags-container" fxLayoutGap="10px">
                <a *ngFor="let tag of tags" class="tag-link" [routerLink]="['/tag', tag]">#{{tag.replace('#', '')}}</a>
              </div>
            </div>
            <div fxFlex.gt-sm="250px" ngClass.lt-md="margin-top">
              <div class="subtitle">{{'More info' | translate}}</div>
              <div fxLayout="column">
                <app-meta-link *ngIf="superEvent" property="superEvent" [event]="event" [superEvent]="superEvent">
                </app-meta-link>
                <app-meta-link *ngFor="let property of metaProperties" [property]="property" [event]="event">
                </app-meta-link>
              </div>
            </div>
          </div>

          <div class="festival-program" *ngIf="event.type == 'super-event' && superProgram && superProgram.length > 0">
            <h3 class="mat-subheading-2">{{ 'Program' | translate }} ({{programHits}})</h3>
            <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
              <div *ngFor="let childEvent of superProgram" fxFlex="auto" fxLayout="row" fxLayout.xs="column"
                fxLayoutGap="10px" fxLayoutGap.xs="0" fxLayoutAlign="start start">
                <div fxFlex="210px" fxFlex.xs="auto">
                  <div class="program-date">{{displayDate(childEvent.startDate)}}</div>
                  <div class="program-time">{{displayTime(childEvent.startDate) + (childEvent.duration > 0 ? ' - ' +
                    displayTime(childEvent.endDate) : '')}}</div>
                </div>
                <div fxLayout="column" fxFlex.xs="grow">
                  <a [routerLink]="['/event', childEvent.event_slug]"
                    class="blue program-item">{{utilsService.getTitle(childEvent)}}</a>
                  <div class="sub-row-program"><span>{{'Org' | translate}}.:</span>
                    {{getOrganizerNames(childEvent.organizers)}}<span *ngIf="childEvent.venue?.name">. {{ 'Venue' |
                      translate }}: {{childEvent.venue?.name}}</span><span>. {{ 'Categories' |
                      translate }}: {{getCategoryNames(childEvent.categories)}}</span></div>
                </div>
              </div>
              <div fxLayout="row" class="full-width margin-top margin-bottom" fxLayoutAlign="center center">
                <button *ngIf="programMoreAvailable" (click)="loadMoreProgramEvents()" mat-stroked-button>
                  <mat-icon>cached</mat-icon> {{'Load more events' | translate}}
                </button>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center"
            fxLayoutAlign.lt-lg="start start" fxLayoutGap.lt-lg="30px" class="full-width">
            <div fxFill.lt-lg>
              <a (click)="reportEvent(event.id)" mat-button>{{'Inappropriate content' | translate}}?</a>
            </div>
            <div *ngIf="userCanEdit" fxLayoutAlign="start center" fxLayoutGap="15px">
              <a color="warn" mat-stroked-button (click)="deleteEvent(event.id)"><span translate>Delete</span> </a>
              <a [routerLink]="['/edit-event/',event.id]" mat-stroked-button>{{'Edit' | translate}}</a>
              <a *ngIf="event.registrationEnabled" [routerLink]="['/bookings']"
                [queryParams]="{ eventId: event.id, startDateSeconds: event.startDate.seconds, venueName: event.venue?.name }"
                mat-stroked-button>{{'See tickets' | translate}}</a>
            </div>
          </div>

          <div *ngIf="environment.ui.showOnForm.rsvp && event?.registrationEnabled && !hasPassed(event)">
            <app-booking-guest [event]="event"></app-booking-guest>
          </div>

          <div class="related-events margin-bottom"
            *ngIf="event.organizers[0]?.name && relatedEventsByOrganizers?.length > 0">
            <h3 class="mat-subheading-2">{{ 'More events hosted by' | translate }} <span
                class="organizer-name">{{event.organizers[0]?.name}}</span> <a class="more-events"
                [routerLink]="['/organizer', event.organizers[0].slug]">{{'See all' | translate}}</a></h3>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" fxLayoutAlign="start start">
              <a class="full-width" fxLayout="column" fxLayoutGap="10px"
                *ngFor="let relatedEventByOrganizer of relatedEventsByOrganizers"
                [routerLink]="['/event', relatedEventByOrganizer.event_slug]" fxFlex="30" fxFlex.lt-lg="100">
                <img [src]="relatedEventByOrganizer.images[0]?.urlLarge" />
                <div class="related-date third-color">{{getRelatedStartDate(relatedEventByOrganizer)}}</div>
                <div class="related-title">{{relatedEventByOrganizer.title_nb}}</div>
                <div class="related-venue-mode">{{relatedEventByOrganizer.venue?.name || 'Online'}}</div>
              </a>
            </div>
          </div>

          <div class="related-events margin-bottom" *ngIf="relatedEventsByCategories?.length > 0">
            <h3 class="mat-subheading-2">{{ 'Other similar events' | translate }}</h3>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" fxLayoutAlign="start start">
              <a class="full-width" [routerLink]="['/event', relatedEventByCategories.event_slug]" fxLayout="column"
                fxLayoutGap="10px" *ngFor="let relatedEventByCategories of relatedEventsByCategories" fxFlex="30"
                fxFlex.lt-lg="100">
                <img [src]="relatedEventByCategories.images[0]?.urlLarge" />
                <div class="related-date third-color">{{getRelatedStartDate(relatedEventByCategories)}}</div>
                <div class="related-title">{{relatedEventByCategories.title_nb}}</div>
                <div class="related-venue-mode">{{relatedEventByCategories.venue?.name || 'Online'}}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="trdevents" [ngClass]="{'vmfest-font' : isVMFest}" *ngIf="environment.theme == 'trdevents'">
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="event != null && !isLoading">
    <div class="top full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <h1>{{utilsService.getTitle(event)}}</h1>
    </div>
    <div class="middle" *ngIf="event.images?.length > 0">
      <app-image-carrousel [images]="event.images" [isVMFest]="isVMFest"></app-image-carrousel>
    </div>
    <div class="bottom">
      <div class="event-warning passed" *ngIf="hasPassed(event)" translate>This event has passed</div>
      <div class="title">{{utilsService.getTitle(event)}}</div>
      <div class="dates" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div class="box" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px"
          *ngIf="event.repetitions?.length == 0">
          <div class="date">{{getDate(event.startDate)}}</div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <div class="venue">{{event.venue?.name}}</div>
            <div *ngIf="event.eventCancelled">{{'cancelled_event' | translate}}</div>
            <div *ngIf="!event.eventCancelled && event.eventSoldOut">{{'Sold out' | translate}}</div>
            <a *ngIf="!event.eventCancelled && !event.eventSoldOut && event.ticketsURL" target="_blank" class="blue"
              [href]="event.ticketsURL">{{'Tickets' | translate}}</a>
          </div>
        </div>
        <div *ngFor="let rep of event.repetitions; let i = index;">
          <div class="box" *ngIf="i < 2 || expandedRep" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
            <div class="date">{{getDate(rep.startDate)}}</div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div class="venue">{{rep.venue?.name}}</div>
              <div *ngIf="rep.eventCancelled">{{'cancelled_event' | translate}}</div>
              <div *ngIf="!rep.eventCancelled && rep.eventSoldOut">{{'Sold out' | translate}}</div>
              <a *ngIf="!rep.eventCancelled && !rep.eventSoldOut && rep.ticketsURL?.length>0" target="_blank"
                class="blue" [href]="rep.ticketsURL">{{'Tickets' | translate}}</a>
            </div>
          </div>
          <a (click)="expandedRep = true" class="box" *ngIf="i == 2 && !expandedRep" fxLayout="column"
            fxLayoutAlign="start start" fxLayoutGap="5px">
            <div class="date">+{{event.repetitions.length - 2}}</div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div class="venue">{{'See all' | translate}}</div>
            </div>
          </a>
        </div>

      </div>
      <div class="categories" fxLayout="row wrap" fxLayoutGap="15px">
        <div class="category" *ngFor="let category of categories">{{category}}</div>
      </div>
      <div class="extra-links" fxLayout="row wrap" fxLayoutGap="15px">
        <a *ngIf="event.moreInfoURL" target="_blank" [href]="event.moreInfoURL">{{'More info' | translate }}</a>
        <a *ngIf="event.streamingURL" target="_blank" [href]="event.streamingURL">{{'Streaming' | translate }}</a>
        <a *ngIf="event.facebookURL" target="_blank" [href]="event.facebookURL">{{'Facebook event' | translate }}</a>
      </div>
      <div class="extra-links" fxLayout="row wrap" fxLayoutGap="15px">
        <a class="favorite-button" target="_blank" (click)="toggleFavorite(event.event_slug)">
          <span *ngIf="isFavorite" fxFlexLayout="row" fxFlexAlignment="center center">{{'Remove from favorites' |
            translate }}</span>
          <span *ngIf="!isFavorite" fxFlexLayout="row" fxFlexAlignment="center center">{{'Add to favorites' | translate
            }}</span>
        </a>
      </div>
      <div class="description full-width">
        <div class="mat-body summary" *ngIf="utilsService.getSummary(event) as summary">{{summary}}</div>
        <div class="mat-body description" *ngIf="utilsService.getDesc(event) as description" [innerHTML]="description">
        </div>
        <div class="embedded-video-container" *ngIf="videoSRC">
          <iframe style="height: 280px; width: 100%;" [src]="videoSRC" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="tags-container" fxLayoutGap="10px">
          <div *ngFor="let tag of tags" class="tag-link">#{{tag.replace('#', '').replace('@', '')}}</div>
        </div>

        <div class="actions">

          <div class="action-title" *ngIf="event.venue">{{'Venue' | translate}}</div>
          <div *ngIf="event.venue" fxLayout="column" fxLayoutAlign="start start">
            <div class="action-label">{{event.venue?.name}}</div>
            <a
              [href]="'https://www.google.com/maps/search/?api=1&query=' + event.venue.location.latitude + '%2C' + event.venue.location.longitude">{{event.venue.address}}</a>
            <div *ngIf="event.venueNote" class="venue-notes">{{'Note' | translate}}: {{event.venueNote}}</div>
          </div>

          <div class="action-title" *ngIf="event.organizers?.length > 0">{{'Organizers' | translate}}</div>
          <div *ngIf="event.organizers" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <div *ngFor="let organizer of event.organizers" fxLayout="column">
              <div class="action-label">{{organizer?.name}}</div>
              <a *ngIf="organizer.website" [href]="organizer.website">{{organizer.website}}</a>
              <a *ngIf="organizer.email" [href]="'mailto:'+organizer.website">{{organizer.email}}</a>
              <a *ngIf="organizer.phone" [href]="'tel:'+organizer.phone">{{organizer.phone}}</a>
            </div>
          </div>

          <div class="action-title">{{'Share' | translate}}</div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
            <a [href]="'https://www.facebook.com/dialog/share?app_id='+environment.facebookAppID+'&display=popup&href=' + event.eventLink "
              target="_blank"><img class="icon-share"
                [src]="'../../assets/icons/'+(isVMFest ? 'vmfest-' : '')+'facebook-black.png'" /></a>
            <a [href]="'https://twitter.com/intent/tweet?text='+ event.title_nb +'...&url=' + event.eventLink"
              target="_blank"><img class="icon-share"
                [src]="'../../assets/icons/'+(isVMFest ? 'vmfest-' : '')+'x-icon.png'" /></a>
          </div>

          <div class="action-title">{{'Save' | translate}}</div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
            <a (click)="generateICS()">ICS</a>
            <a [href]="getLinkForGoogleCalendar()" target="_blank">{{'Google Calendar' | translate}}</a>
          </div>
        </div>


      </div>

      <div class="margin-top" fxLayout="column" fxLayoutGap="30px">
        <div class="festival-program" *ngIf="event.type == 'has-super' && superEvent">
          <h3 class="mat-subheading-2">{{ 'Part of program' | translate }}</h3>
          <div>{{'This event is a part of' | translate }}: <a class="blue"
              [routerLink]="['/event', superEvent.event_slug]">{{superEvent.title_nb}}</a></div>
        </div>
        <div class="festival-program" *ngIf="event.type == 'super-event' && superProgram && superProgram.length > 0">
          <h3 class="mat-subheading-2">{{ 'Program' | translate }} ({{programHits}})</h3>
          <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
            <div *ngFor="let childEvent of superProgram" fxFlex="auto" fxLayout="row" fxLayout.xs="column"
              fxLayoutGap="10px" fxLayoutGap.xs="0" fxLayoutAlign="start start">
              <div fxFlex="210px" fxFlex.xs="auto">
                <div class="program-date">{{displayDate(childEvent.startDate)}}</div>
                <div class="program-time">{{displayTime(childEvent.startDate) + (childEvent.duration > 0 ? ' - ' +
                  displayTime(childEvent.endDate) : '')}}</div>
              </div>
              <div fxLayout="column" fxFlex.xs="grow">
                <a [routerLink]="['/event', childEvent.event_slug]"
                  class="blue program-item">{{utilsService.getTitle(childEvent)}}</a>
                <div class="sub-row-program"><span>{{'Org' | translate}}.:</span>
                  {{getOrganizerNames(childEvent.organizers)}}<span *ngIf="childEvent.venue?.name">. {{ 'Venue' |
                    translate }}: {{childEvent.venue?.name}}</span><span>. {{ 'Categories' |
                    translate }}: {{getCategoryNames(childEvent.categories)}}</span></div>
              </div>
            </div>
            <div fxLayout="row" class="full-width margin-top margin-bottom" fxLayoutAlign="center center">
              <button *ngIf="programMoreAvailable" (click)="loadMoreProgramEvents()" mat-stroked-button>
                <mat-icon>cached</mat-icon> {{'Load more events' | translate}}
              </button>
            </div>
          </div>
        </div>


        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-lg="start start" fxLayoutGap.lt-lg="30px" class="full-width margin-bottom">
          <div fxFill.lt-lg>
            <a (click)="reportEvent(event.id)" mat-button>{{'Inappropriate content' | translate}}?</a>
          </div>
          <div *ngIf="userCanEdit" fxLayoutAlign="start center" fxLayoutGap="15px">
            <a color="warn" mat-stroked-button (click)="deleteEvent(event.id)"><span translate>Delete</span> </a>
            <a [routerLink]="['/edit-event/',event.id]" mat-stroked-button>{{'Edit' | translate}}</a>
            <a *ngIf="event.registrationEnabled" [routerLink]="['/bookings']"
              [queryParams]="{ eventId: event.id, startDateSeconds: event.startDate.seconds, venueName: event.venue?.name }"
              mat-stroked-button>{{'See tickets' | translate}}</a>
          </div>
        </div>

        <div *ngIf="environment.ui.showOnForm.rsvp && event?.registrationEnabled && !hasPassed(event)">
          <app-booking-guest [event]="event"></app-booking-guest>
        </div>

        <div class="related-events margin-bottom"
          *ngIf="event.organizers[0]?.name && relatedEventsByOrganizers?.length > 0">
          <h3 class="mat-subheading-2">{{ 'More events hosted by' | translate }} <span
              class="organizer-name">{{event.organizers[0]?.name}}</span></h3>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" fxLayoutAlign="start start">
            <app-event-tile [isVMFest]="isVMFest" fxFlex="30" [event]="relatedEventByOrganizer"
              *ngFor="let relatedEventByOrganizer of relatedEventsByOrganizers"></app-event-tile>
          </div>
        </div>

        <div class="related-events margin-bottom" *ngIf="relatedEventsByCategories?.length > 0">
          <h3 class="mat-subheading-2">{{ 'Other similar events' | translate }}</h3>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" fxLayoutAlign="start start">
            <app-event-tile [isVMFest]="isVMFest" fxFlex="30" [event]="relatedEventByCategories"
              *ngFor="let relatedEventByCategories of relatedEventsByCategories"></app-event-tile>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>