<div #top></div>
<div class="full-width message waiting-list-warning" *ngIf="show != 'booking-success' && goesToWaitingList">
    {{'This event is sold out. You can book your tickets and you will enter into the waiting list. In case you get a
    seat, you will receive an SMS from us with the confirmation.' | translate}}
</div>

<form fxLayout="column" class="full-width" fxLayoutGap="30px" #bookingFormEle *ngIf="show != 'booking-success'">

    <app-booking-event-selector *ngIf="event?.repetitions?.length > 0" [eventFormControl]="eventFormControl" (onEventsLoaded)="onEventsLoaded($event)" 
        [filter]="{eventID: event?.id}" [showRangeFilter]="false"
        [toucher]="toucher" [showAllMyEventsOption]="false" [hint]="'Select a date and venue' | translate"></app-booking-event-selector>

    <div fxLayout="row" fxLayout.lt-lg="column" class="full-width" fxLayoutAlign="start start" fxLayoutGap="30px">
        <app-customer-name-editor fxFlex="grow" fxFlex.xs="auto" fxFill (onNameChange)="onCustomerNameChange($event)" 
        (onValidityChange)="validityOnComponent($event, 1)" [toucher]="toucher"></app-customer-name-editor>
        <app-customer-phone-editor fxFlex="300px" fxFlex.xs="auto" fxFill 
        [eventId]="selectedInstance?.id || event.id" 
        [eventStartDateSeconds]="selectedInstance?.startDate?.seconds || event.startDate?.seconds"
        [eventVenueName]="selectedInstance?.venue?.name || selectedInstance?.venueObj?.name || event.venue?.name || event.venueObj?.name" 
        (onPhoneChange)="onCustomerPhoneChange($event)" [toucher]="toucher"
        (onValidityChange)="validityOnComponent($event, 2)"></app-customer-phone-editor>
    </div>

    <app-customer-address-editor *ngIf="addressCompulsory" [toucher]="toucher"
        (onCustomerAddressChange)="onCustomerAddressChange($event)"
        [isRequired]="addressCompulsory" (onValidityChange)="validityOnComponent($event, 3)"></app-customer-address-editor>

    <app-customer-comments-editor (onValidityChange)="validityOnComponent($event, 4)"
        (onCustomerCommentsChange)="onCustomerCommentsChange($event)"></app-customer-comments-editor>

    <app-ticket-selector *ngIf="event?.repetitions?.length == 0 || selectedInstance" class="full-width margin-top"
        [eventId]="selectedInstance?.id || event.id"
        [startDateSeconds]="selectedInstance?.startDate?.seconds || event.startDate?.seconds" [toucher]="toucher"
        [venueName]="selectedInstance?.venueObj?.name || event.venueObj?.name"
        (onTicketsChange)="onTicketsChange($event)"
        (onValidityChange)="validityOnComponent($event, 5)"></app-ticket-selector>

    <div fxLayout="column" class="full-width" fxLayoutGap="20px">
        <ul class="full-width important-notes">
            <li>{{'Payment method' | translate }}: <strong>{{(selectedInstance || event).paymentMethod == 'invoice' ? ('Invoice' |
                    translate) : ('At the door' | translate)}}</strong></li>
            <li>{{'Cancellation period' | translate }}: <strong>{{event.cancellationPeriod}}</strong> {{'days before
                the event starts' | translate}}</li>
            <li>{{'By booking these tickets, I accept the' | translate }} <a class="blue"
                    href="/terms-and-conditions-rsvp" target="_blank">{{'terms and conditions' | translate }}</a>
            </li>
        </ul>
    </div>

    <div fxLayout="row" class="full-width" fxLayoutAlign="end end">
        <div fxLayout="row" fxLayoutGap="10px">
            <button mat-raised-button color="primary" (click)="bookTickets()">{{ goesToWaitingList ? ('Apply to waiting
                list' | translate ): ('Book tickets' | translate) }}</button>
        </div>
    </div>

</form>

<div class="full-width message success-booking" *ngIf="show == 'booking-success'">
    {{'Your tickets were successfully booked' | translate}}. {{'We have sent you an SMS with the confirmation' |
    translate}}. {{ environment.contact?.phone ? ('If you have any questions, you can call us at' |
    translate) + ": " + environment.contact?.phone + ".": ''}}
</div>