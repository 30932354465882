import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { EventRepetition } from '../models/event-repetition';
import { UtilsService } from '../services/utils.service';
import { debounceTime, first } from 'rxjs/operators';

import moment from 'moment';
import { SelectableEvent } from '../models/selectable-event';
import { FormGroup, Validators, FormControl, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import { Venue } from '../models/venue';
import { MatDialog } from '@angular/material/dialog';
import { ChangeStartDateComponent } from '../change-start-date/change-start-date.component';
import { AddTicketTypeComponent } from '../add-ticket-type/add-ticket-type.component';
import { TicketType } from '../models/ticket-type';
import { TicketTypeService } from '../services/ticket-type.service';

@Component({
  selector: 'app-event-repetition',
  templateUrl: './event-repetition.component.html',
  styleUrls: ['./event-repetition.component.scss']
})
export class EventRepetitionComponent implements OnInit {

  @Input() eventRepetition: EventRepetition;

  @Input() registrationEnabled: boolean;

  @Input() allVenues: Venue[];

  @Input() minDate: any;

  @Output() removed = new EventEmitter();

  @Output() updated = new EventEmitter<EventRepetition>();

  hasClicked: boolean = false;
  durations: SelectableEvent[];
  regexpURL = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  repetitionForm = new FormGroup({
    availableTickets: new FormControl(null, [Validators.required]),
    duration: new FormControl(0, []),
    eventCancelled: new FormControl(false, []),
    eventSoldOut: new FormControl(false, []),
    mode: new FormControl('offline', []),
    paymentMethod: new FormControl('door'),
    prices: new FormArray([
      new FormGroup({
        type: new FormControl('REGULAR'),
        name_nb: new FormControl('Vanlig'),
        name_en: new FormControl('Regular'),
        price: new FormControl(null, [Validators.min(0)]),
      })
    ], { validators: this.pricesValidator() }),
    startDate: new FormControl(),
    startTime: new FormControl(),
    ticketsURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
    venue: new FormControl('', []),
    venueObj: new FormControl('', []),
  });

  //Prices
  allTicketTypes: TicketType[];
  allTicketsAreFree: boolean = true;

  constructor(
    public dialog: MatDialog,
    private ticketTypeService: TicketTypeService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.initTicketTypes();
    this.durations = this.utilsService.getAllDurations();
    this.buildPricesControls((<FormArray>this.repetitionForm.get("prices")), this.eventRepetition);
    this.repetitionForm.patchValue(this.eventRepetition);
    this.repetitionForm.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.updated.emit({
        ... this.eventRepetition,
        ... this.repetitionForm.value,
        venueObj: this.allVenues.find(v => v.id == this.repetitionForm.controls["venue"].value) || null
      });
    });
  }

  getStartDate() {
    let startDate = moment(this.eventRepetition.startDate);
    if (!startDate.isValid()) {
      startDate = moment.unix(this.eventRepetition.startDate.seconds);
    }
    return startDate.format(this.utilsService.getDateFormat(true)) + " @ " + this.utilsService.getLocalizedHour(this.eventRepetition.startTime);
  }

  getEndTime() {
    let startDate = moment(this.eventRepetition.startDate);
    if (!startDate.isValid()) {
      startDate = moment.unix(this.eventRepetition.startDate.seconds);
    }
    if (this.repetitionForm.controls["duration"].value > 0) {
      return startDate.add(this.repetitionForm.controls["duration"].value, 'minutes').format(this.utilsService.getTimeFormat())
    }
  }

  remove() {
    this.removed.emit();
  }

  changeStartDate() {
    let dialogRef = this.dialog.open(
      ChangeStartDateComponent, {
      width: '375px',
      data: { startDate: this.eventRepetition.startDate, startTime: this.eventRepetition.startTime, minDate: this.minDate }
    });
    dialogRef.afterClosed().pipe(first()).subscribe((newDate) => {
      if (newDate) {
        //Update the start date
        this.repetitionForm.patchValue(newDate);
      }
    });
  }

  /* PRICES */
  buildPricesControls(pricesControl: FormArray, eventRepetition: EventRepetition) {
    if (eventRepetition.prices?.length > 0) {
      for (let i = 1; i < eventRepetition.prices.length; i++) {
        pricesControl.push(
          new FormGroup({
            type: new FormControl('', [Validators.required]),
            price: new FormControl('', [Validators.min(0), Validators.required]),
            name_nb: new FormControl(),
            name_en: new FormControl(),
          })
        )
      };
    }
  }

  removePrice(i: number) {
    (<FormArray>this.repetitionForm.get('prices')).removeAt(i);
  }

  addPrice() {
    (<FormArray>this.repetitionForm.get('prices')).push(new FormGroup({
      type: new FormControl('', [Validators.required]),
      name_nb: new FormControl(),
      name_en: new FormControl(),
      price: new FormControl('', [Validators.min(0), Validators.required]),
    }));
  }

  initTicketTypes() {
    console.log("initTicketTypes")
    this.ticketTypeService.getAllTicketTypes().pipe(first()).subscribe(
      (allTT: TicketType[]) => {
        this.allTicketTypes = allTT;
      }
    );
  }

  addTicketType() {
    let dialogRef = this.dialog.open(
      AddTicketTypeComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.initTicketTypes();
    });
  }

  setTicketTypeNames(option, i) {
    const tt = this.allTicketTypes.find((ttt) => ttt.id == option.value);
    console.log(tt);
    if (tt) {
      this.repetitionForm.get('prices')['controls'][i].get('name_nb').setValue(tt?.name_nb || '');
      this.repetitionForm.get('prices')['controls'][i].get('name_en').setValue(tt?.name_en || '');
    }
  }

  evaluateIfAllTicketsAreFree(): void {
    this.allTicketsAreFree = true;
    for (let i = 0; i < this.repetitionForm.get('prices')['controls'].length; i++) {
      if (this.repetitionForm.get('prices')['controls'][i].get('price').value > 0) {
        this.allTicketsAreFree = false;
      }
    }
  }

  //It validates that we have one or more prices and all of them are distinct
  pricesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length > 1) {
        const allDistinct = new Set(control.value.map(ele => ele.type)).size == control.value.length;
        if (!allDistinct) {
          return { 'allDistinct': true };
        }
      }
      return null;
    };
  }

}
