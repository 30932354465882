import { Injectable, Inject, inject } from '@angular/core';


import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { User } from '@firebase/auth-types';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Event } from '../models/event';

import { Functions, httpsCallable } from '@angular/fire/functions';
import { Firestore, collection, collectionData, deleteDoc, doc, getDoc, query, updateDoc, where, addDoc, onSnapshot, or, QueryFieldFilterConstraint, setDoc } from '@angular/fire/firestore';
import { Storage, deleteObject, getDownloadURL, ref, uploadBytesResumable } from '@angular/fire/storage';

import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { RepetitionService } from './repetition.service';
import { environment } from '../../environments/environment';

import moment from 'moment';
import { Filter } from '../models/filter';
import { TeamService } from './team.service';
import { Team } from '../models/team';
import { RollbarService } from '../modules/rollbar';
import Rollbar from 'rollbar';
import { Warning } from '../models/warning';


@Injectable()
export class EventService {

  private firestore: Firestore = inject(Firestore);
  private functions: Functions = inject(Functions);
  private storage: Storage = inject(Storage);

  //Transform from snapshotdoc to event
  transformFirestoreEvents = map((actions: any[]) => (actions && actions.length > 0 && actions.map(a => {
    const data = a.payload.doc.data();
    const id = a.payload.doc.id;
    return {
      id,
      ...data,
    };
  })) || []);

  plainTransformRepetitionDates = (event: Event) => {
    if (event) {
      event.repetitions = event?.repetitions?.map(
        (rep) => ({
          ...rep,
          startDate: new Date(rep.startDate.seconds * 1000)
        })
      ) || [];
    }
    return event;
  };
  transformRepetitionDates = map(this.plainTransformRepetitionDates)

  transformEventDates = (graphqlEvent: any) => {
    let event = { ...graphqlEvent };
    event.startDate = { seconds: moment(event.startDate, "YYYY-MM-DD HH:mm:ssZ").unix() };
    event.endDate = { seconds: moment(event.endDate, "YYYY-MM-DD HH:mm:ssZ").unix() };
    if (event.created_at) {
      event.created_at = { seconds: moment(event.created_at, "YYYY-MM-DD HH:mm:ssZ").unix() };
    }
    if (event.updated_at) {
      event.updated_at = { seconds: moment(event.updated_at, "YYYY-MM-DD HH:mm:ssZ").unix() };
    }
    if (event.publishingDate) {
      event.publishingDate = { seconds: moment(event.publishingDate, "YYYY-MM-DD HH:mm:ssZ").unix() };
    }
    if (event.ticketsFromDate) {
      event.ticketsFromDate = { seconds: moment(event.ticketsFromDate, "YYYY-MM-DD HH:mm:ssZ").unix() };
    }
    if (event.repetitions) {
      event.repetitions = this.repetitionService.transformRepetitionFromGraphQL(event.repetitions);
    }
    return event;
  }

  //Transform the dates and the repetitions for an array of events with metada on pagination
  transformGraphQLEventsWithPagination = map((graphqlEvents: any) =>
    graphqlEvents?.data?.events?.data?.length > 0 ? graphqlEvents.data.events.data.map(this.transformEventDates) : []
  );


  //Transform the dates and the repetitions for an array of events
  transformGraphQLEvents = (functionName: string) => map((graphqlEvents: any) => {
    if (graphqlEvents?.data[functionName]?.length > 0) {
      graphqlEvents = graphqlEvents.data[functionName].map(this.transformEventDates);
      return graphqlEvents;
    } else {
      return [];
    }
  });

  //Transform the dates and the repetitions for an event returned by graphQL
  transformGraphQLEvent = (graphQLFunction: string) => map((graphqlEvent: any) => {
    if (graphqlEvent?.data[graphQLFunction]) {
      return this.transformEventDates(graphqlEvent.data[graphQLFunction]);
    }
    return null;
  });

  includeID = map((action: any) => {
    if (action.payload.data()) {
      return {
        id: action.payload.id,
        ...action.payload.data()
      }
    }
  });

  //Filter events that took place already
  filterPassedEvents = map((events: Event[]) => events.filter((event: Event) => (event.endDate?.seconds * 1000) > (new Date()).getTime() || event.repetitions?.filter((rep) => (rep.startDate?.seconds * 1000) > (new Date()).getTime())?.length > 0));

  //Order by startDate
  orderEventsByStartDate = map((events: Event[]) => events?.sort((a, b) => { return a.startDate < b.startDate ? -1 : 1; }) || []);

  //Returns a list with the user events, including the parent events of the repetitions that match the criteria
  filterByTimeEvents = (myEvents: Event[], pastEvents: boolean) => {

    let filteredEvents: Event[] = [];
    if (myEvents?.length > 0) {
      //Get the time as of now
      let timeInSeconds = new Date().getTime() / 1000;
      for (let myEvent of myEvents) {
        let firstDateIn: boolean = false;
        let anyRepetitionIn: boolean = false;
        //Filter my events including repetitions
        if (pastEvents) {
          firstDateIn = myEvent.endDate.seconds <= timeInSeconds;
          anyRepetitionIn = myEvent.repetitions?.some((rep) => (rep.startDate.seconds + (rep.duration || 0) * 60) <= timeInSeconds) || false;
        } else {
          firstDateIn = myEvent.endDate.seconds > timeInSeconds;
          anyRepetitionIn = myEvent.repetitions?.some((rep) => (rep.startDate.seconds + (rep.duration || 0) * 60) > timeInSeconds) || false;
        }
        if (firstDateIn || anyRepetitionIn) {
          filteredEvents.push(myEvent);
        }
      }
      return filteredEvents;
    }
  };

  //Adds the current event as the first repetition
  transformEventWithRep = (event: Event, includeAllRepetitions: boolean) => {
    let adjustedEvent = event;
    if (adjustedEvent.repetitions.length > 0) {
      adjustedEvent = {
        ...adjustedEvent,
        //Add the master event as the first of all repetitions
        repetitions: [
          {
            activeTickets: event.activeTickets,
            availableTickets: event.availableTickets,
            startDate: event.startDate,
            endDate: event.endDate,
            startTime: event.startTime,
            duration: event.duration,
            eventCancelled: event.eventCancelled,
            eventSoldOut: event.eventSoldOut,
            mode: event.mode,
            ticketsURL: event.ticketsURL,
            streamingURL: event.streamingURL,
            venue: event.venue,
            venueObj: event.venueObj,
          },
          ...event.repetitions,
        ]
      };
      if (!includeAllRepetitions) {
        //Filter only the upcoming repetitions
        adjustedEvent.repetitions = adjustedEvent.repetitions?.filter((rep) => {
          const endDate = moment.unix(rep.startDate.seconds + rep.duration * 60);
          return endDate.isAfter(moment());
        }) || [];
      }
    }
    return adjustedEvent;
  };
  maybeIncludeRepetition = (includeAllRepetitions: boolean) => map((event: Event) => this.transformEventWithRep(event, includeAllRepetitions));

  eventFields: string =
    `activityTypes
    ageRestriction  
    activeTickets
    availableTickets
    author_id
    cancellationPeriod
    categories
    desc_en
    desc_nb
    duration
    editableBy
    embeddedVideoURL
    endDate
    eventCancelled
    eventSoldOut
    event_slug
    eventLink
    facebookURL
    id
    images {
      alt
      caption
      credits
      urlLarge
      urlSmall
      urlOriginal
      originalSize
      largeSize
      smallSize
    }
    isFeaturedEvent
    maximumAge
    mazeMapPoi
    minimumAge
    mode
    moreInfoURL
    noTicketsInfo
    organizers {
      id
      email
      name
      slug
      telephoneNumber
      website
    }
    paymentMethod
    priceOption
    publishingDate
    prices {
      type
      price
      name_nb
      name_en
    }
    reducedPrice
    regularPrice
    registrationEnabled
    repetitions {
      activeTickets
      availableTickets
      startDate
      endDate
      startTime
      duration
      eventCancelled
      eventSoldOut
      mode
      ticketsURL
      streamingURL
      venue {
        address
        id
        location {
          latitude
          longitude
        }
        mapImageURL
        name
        slug
      }
    }
    startDate
    startTime
    streamingURL
    summary_en
    summary_nb
    super_event
    tags
    ticketsFromDate
    ticketsFromTime
    ticketsURL
    title_en
    title_nb
    type
    updated_at
    venue {
      address
      id
      location {
        latitude
        longitude
      }
      mapImageURL
      name
      slug
    }
    venueNote
    videosURL`;

  constructor(
    private teamService: TeamService,
    private utilsService: UtilsService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private repetitionService: RepetitionService,
    private http: HttpClient,
    private authService: AuthService) {
  }

  //GETTING BLOCK
  getCache() {
    const docRef = doc(this.firestore, 'settings', "cache");
    return from(getDoc(docRef).then((snap) => (snap.exists() ? { id: snap.id, ...snap.data() } : null)));
  }

  getWarning(): Observable<Warning> {
    const docRef = doc(this.firestore, 'settings', "warning");
    return from(getDoc(docRef).then((snap) => (snap.exists() ? { id: snap.id, ...snap.data() } : null))).pipe(
      catchError((error) => {
        this.rollbar.error('Error getWarning', JSON.stringify(error));
        return of(null);
      }));
  }

  getTransformGraphQLEventsWithPagination() {
    return this.transformGraphQLEventsWithPagination;
  }

  getEvents(filter: Filter, page?: number): Observable<any> {

    const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/graphQL`;
    let formattedFilter: any = {};

    //Filter from date
    if (filter.fromDate != null) {
      formattedFilter.fromDate = moment(filter.fromDate, 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ssZ");
    }

    //Sort by
    if (filter.sortBy != null) {
      formattedFilter.sortBy = filter.sortBy;
    }

    //Filter until date
    if (filter.untilDate != null) {
      formattedFilter.untilDate = moment(filter.untilDate, 'DD/MM/YYYY').set({ hour: 23, minute: 59, second: 0 }).utc().format("YYYY-MM-DD HH:mm:ssZ");
    }

    //Filter by venue slug
    if (filter.venueSlug && filter.venueSlug != null) {
      formattedFilter.venueSlug = filter.venueSlug.replace('"', '\\\"');
    }

    //Filter by organizer slug
    if (filter.organizerSlug && filter.organizerSlug != null) {
      formattedFilter.organizerSlug = filter.organizerSlug.replace('"', '\\\"');
    }

    //Filter by event mode
    if (filter.mode && filter.mode != null) {
      formattedFilter.mode = filter.mode;
    }

    //Filter search term
    if (filter.searchTerm) {
      formattedFilter.searchTerm = filter.searchTerm.replace('"', '\\\"');
    }

    //Filter municipality
    if (filter.municipality) {
      formattedFilter.municipality = filter.municipality.replace('"', '\\\"');
    }

    //Filter by super event ID
    if (filter.superEvent && filter.superEvent != null) {
      formattedFilter.superEvent = filter.superEvent;
    }

    //Filter categories
    if (filter.categories?.length > 0) {
      formattedFilter.categories = filter.categories;
    }

    //Filter activity types
    if (filter.activityTypes?.length > 0) {
      formattedFilter.activityTypes = filter.activityTypes;
    }

    //Filter tag
    if (filter.tag) {
      formattedFilter.tag = filter.tag.replace('"', '\\\"');
    }

    //Filter only featured
    if (filter.onlyFeatured) {
      formattedFilter.onlyFeatured = filter.onlyFeatured;
    }

    //Filter only featured
    if (filter.onlyFeaturedVMFest) {
      formattedFilter.onlyFeaturedVMFest = filter.onlyFeaturedVMFest;
    }

    //Filter only VMFest
    if (filter.onlyVMFest) {
      formattedFilter.onlyVMFest = filter.onlyVMFest;
    }

    //Filter hour range
    if (filter.hoursRange) {
      formattedFilter.hoursRange = filter.hoursRange;
    }

    const filterStr = formattedFilter ? `filter: ${JSON.stringify(formattedFilter).replace(/"([^"]+)":/g, '$1:')}` : '';
    const pageStr = page ? `page: ${page}` : '';
    let pageSizeStr = '';
    if (environment.theme == 'trdevents') {
      if (filter.onlyFeatured || filter.onlyFeaturedVMFest) {
        pageSizeStr = 'pageSize: 6';
      } else {
        if (filter.categories?.length == 0 && filter.fromDate == null && filter.untilDate == null && filter.searchTerm == null) {
          pageSizeStr = 'pageSize: 6';
        } else {
          pageSizeStr = 'pageSize: 12';
        }
      }
    }
    const paramStr = (filterStr || pageStr || pageSizeStr) ? '(' + [filterStr, pageStr, pageSizeStr].join(", ").replace(", ,", ',') + ')' : '';
    let query =
      `{
      events ${paramStr} {
        data {
          ${this.eventFields}
        }
        hasMore
        totalCount
      }
    }`;
    return this.http.post(url, { query }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
      catchError((error) => {
        this.rollbar.error('Error getEvents', JSON.stringify(error));
        return of([]);
      })
    );
  }

  //Get an event by the event slug
  getEventBySlug(eventSlug: string): Observable<Event | null> {
    const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/graphQL`;
    let query =
      `{
      eventBySlug(eventSlug: "${eventSlug}") {
        ${this.eventFields}
      }
    }`;
    return this.http.post(url, { query }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
      this.transformGraphQLEvent('eventBySlug'),
      this.maybeIncludeRepetition(false),
      catchError((error) => {
        this.rollbar.error('Error getEventBySlug', JSON.stringify(error));
        return of(null);
      })
    );
  }

  //Get all the events with the given title and unrelated to eventID
  existsEventWithSameTitle(title: string, eventID?: string, license?: string): Observable<boolean> {
    const url = `https://us-central1-${license || environment.firebase.projectId}.cloudfunctions.net/graphQL`;
    const regex = /"/gi;
    let query =
      `{
        eventByTitle(title: "${title.replace(regex, '\\\"')}", lan: "nb") {
          ${this.eventFields}
        }
      }`;
    return this.http.post(url, { query }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
      this.transformGraphQLEvent('eventByTitle'),
      map((ev) => {
        return ev !== null && new Date(ev.endDate?.seconds * 1000) > new Date() && ev.id != eventID;
      }),
      catchError((error) => {
        this.rollbar.error('Error existsEventWithSameTitle', JSON.stringify(error));
        return of(false);
      })
    );
  }

  //Get all the events contained within superEventID
  getChildrenEvents(superEventID: string, page?: number): Observable<Event[]> {
    const superEventFilter = new Filter();
    superEventFilter.superEvent = superEventID;
    return this.getEvents(superEventFilter, page).pipe(
      catchError((error) => {
        this.rollbar.error('Error getChildrenEvents', JSON.stringify(error));
        return of([]);
      })
    );
  }

  //Get an event given an ID
  //Client: GraphQL
  getEventFromGraphQL(eventId: string): Observable<Event | null> {
    const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/graphQL`;
    let query =
      `{
        eventByID(eventID: "${eventId}") {
          ${this.eventFields}
        }
      }`;
    return this.http.post(url, { query }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
      this.transformGraphQLEvent("eventByID"),
      this.maybeIncludeRepetition(false),
      catchError((error) => {
        this.rollbar.error('Error getEventFromGraphQL', JSON.stringify(error));
        return of(null);
      })
    );
  }

  getEventFromFirestore(eventId: string, callback: any) {
    return onSnapshot(doc(this.firestore, "events", eventId), (snap) => {
      let eventTransformed = snap.exists() ? (<Event>{ id: snap.id, ...snap.data() }) : null;
      if (eventTransformed) {
        eventTransformed = this.transformEventWithRep(eventTransformed, true);
        eventTransformed = this.plainTransformRepetitionDates(eventTransformed);
      }
      callback(eventTransformed);
    });
  }

  getEventInstance(eventId: any, startDateSeconds: any, venueName: any) {
    const docRef = doc(this.firestore, 'events', eventId);
    return from(getDoc(docRef).then((snap) => (snap.exists() ? (<Event>{ id: snap.id, ...snap.data() }) : null))).pipe(
      map((event: Event) => {
        if (event.repetitions?.length > 0) {
          const rep = event.repetitions.find((r) => r.startDate.seconds == startDateSeconds && r.venueObj.name == venueName);
          return { ...event, ...rep };
        }
        return event;
      }),
      catchError((error) => {
        this.rollbar.error('Error getEventInstance', JSON.stringify(error));
        return of(null);
      })
    );
  }

  getAllEventsWithTicketsAvailable(): Observable<Event[]> {
    const colRef = collection(this.firestore, 'events');
    const w = where('registrationEnabled', '==', true);
    const q = query(colRef, w);

    return collectionData(q, { idField: "id" })
      .pipe(
        this.orderEventsByStartDate,
        catchError((error) => {
          this.rollbar.error('Error getAllEventsWithTicketsAvailable', error);
          return of(null);
        })
      );
  }

  //User functions
  deleteUserEvents(): void {
    //Delete all the events created by the user
    this.getUserEvents(null).subscribe(
      (events) => events.map(event => {
        event.images.map((image) => {
          this.deleteImageByUrl(image.urlSmall);
          this.deleteImageByUrl(image.urlLarge);
          this.deleteImageByUrl(image.urlOriginal);
        });
        this.deleteEvent(event.id);
      })
    );
  }

  getUserEvents(pastEvents: boolean | null): Observable<Event[]> {
    return this.authService.getCurrentUser()
      .pipe(
        mergeMap((user: User) => {
          const colRef = collection(this.firestore, 'events');
          const w = where('author_id', '==', user.uid.toString());
          const q = query(colRef, w);
          return collectionData(q, { idField: "id" });
        }),
        map((fireEvents: Event[]) => pastEvents === null ? fireEvents : this.filterByTimeEvents(fireEvents, pastEvents)),
        this.orderEventsByStartDate,
        catchError((error) => {
          this.rollbar.error('Error getUserEvents', JSON.stringify(error));
          return of(null);
        })
      );
  }

  //Get all the events that an user can edit 
  getEventsEditableByUser(pastEvents: boolean | null, uid: string): Observable<Event[]> {
    return this.teamService.getTeamsForUserAsEventEditor()
      .pipe(
        mergeMap((teams: Team[] | null) => {
          const colRef = collection(this.firestore, 'events');
          const isAuthor: QueryFieldFilterConstraint = where('author_id', '==', uid);
          let q = query(colRef, isAuthor);
          if (teams?.length > 0) {
            q = query(colRef, or(isAuthor, where('editableBy', 'in', (teams).map(team => team.id))));
          }
          return collectionData(q, { idField: "id" });
        }),
        map((fireEvents: Event[]) => pastEvents === null ? fireEvents : this.filterByTimeEvents(fireEvents, pastEvents)),
        this.orderEventsByStartDate,
        catchError((error) => {
          this.rollbar.error('Error getEventsEditableByUser', JSON.stringify(error));
          return of([]);
        })
      );
  }

  //Get all the events for which a user can see their bookings
  getUserTeamEventsAsBookingEditor(): Observable<Event[]> {
    return this.teamService.getTeamsForUserAsBookingEditor()
      .pipe(
        mergeMap((teams: Team[] | null) => {
          if (teams?.length > 0) {
            const colRef = collection(this.firestore, 'events');
            const w = [where('editableBy', 'in', (teams).map(team => team.id)), where('registrationEnabled', '==', true)];
            const q = query(colRef, ...w);
            return collectionData(q, { idField: "id" });
          } else {
            return of([]);
          }
        }
        ),
        this.orderEventsByStartDate,
        catchError((error) => {
          this.rollbar.error('Error getUserTeamEventsAsBookingEditor', JSON.stringify(error));
          return of([]);
        })
      );
  }

  //Get all the super events
  getAllSuperEvents(): Observable<Event[]> {
    const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/graphQL`;
    let query =
      `{
      allUpcomingSuperEvents {
        ${this.eventFields}
      }
    }`;
    return this.http.post(url, { query }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
      this.transformGraphQLEvents('allUpcomingSuperEvents'),
      catchError((error) => {
        this.rollbar.error('Error getAllSuperEvents', JSON.stringify(error));
        return of([]);
      })
    );
  }

  //Get related events by organizers (only 3)
  getRelatedEventsByOrganizers(event: Event, isVMFest: boolean): Observable<Event[]> {
    const filter: Filter = new Filter();
    filter.organizerSlug = event.organizers[0]?.slug || '';
    if (isVMFest) {
      filter.onlyVMFest = true;
    }
    return this.getEvents(filter).pipe(
      map((res) => res?.data?.events?.data?.filter(dataEvent => event?.id !== dataEvent?.id) || []),
      catchError((error) => {
        this.rollbar.error('Error getRelatedEventsByOrganizers', JSON.stringify(error));
        return of([]);
      })
    );

  }

  //Get related events by categories (only 3)
  getRelatedEventsByCategories(event: Event, isVMFest: boolean): Observable<Event[]> {
    const filter: Filter = new Filter();
    filter.categories = event.categories;
    if (isVMFest) {
      filter.onlyVMFest = true;
    }
    return this.getEvents(filter).pipe(
      map((res) => res?.data?.events?.data?.filter(dataEvent => event?.id !== dataEvent?.id) || []),
      catchError((error) => {
        this.rollbar.error('Error getRelatedEventsByCategories', JSON.stringify(error));
        return of([]);
      })
    );
  }

  //SETTING BLOCK
  deleteEvent(eventId: string): Promise<any> {
    const docRef = doc(this.firestore, 'events', eventId);
    return deleteDoc(docRef);
  }

  deleteImageByUrl(url: string): Promise<any> {
    if (url) {
      const objRef = ref(this.storage, url);
      return deleteObject(objRef);
    }
    return Promise.resolve();
  }

  //Uploads a picture and returns an Observable of the URL
  async uploadImageToFirestore(base64: string, suffix: string, percent: BehaviorSubject<number>) {
    if (base64) {
      const blob = await (await fetch(base64)).blob();
      const fileName = (this.utilsService.makeid(5) + '_' + (suffix || '')) + '.jpg';
      const filePath = '/entities/images/' + fileName;
      const storageRef = ref(this.storage, filePath);
      let downloadURL: string;
      await uploadBytesResumable(storageRef, blob)
        .then(
          async function (snapshot) {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            percent.next(percentage);
            if (snapshot.bytesTransferred == snapshot.totalBytes) {
              downloadURL = await getDownloadURL(snapshot.ref);
            }
          }
        )
        .catch((e) => console.error("Error uploading file", e));
      return downloadURL;
    }
    return of(null);
  }

  async updateEvent(eventID: string, eventFields: any): Promise<string> {
    try {
      const docRef = doc(this.firestore, 'events', eventID);
      await updateDoc(docRef, eventFields);
      return docRef.id;
    } catch (ex) {
      this.rollbar.error('Error updateEvent ' + JSON.stringify(ex));
      return null;
    }
  }

  async addEvent(event: Event): Promise<string> {
    try {
      const maybeEventSlug = this.utilsService.slugify(event.title_nb, '-');
      const maybeEvent = await this.getEventBySlug(maybeEventSlug).toPromise();
      const uniqueSlug = this.utilsService.getUniqueSlug(event.title_nb, maybeEvent);
      const colRef = collection(this.firestore, 'events');
      const docRefData = await addDoc(colRef, { event_slug: uniqueSlug, ...event });
      return docRefData.id;
    } catch (ex) {
      this.rollbar.error('Error addEvent ' + JSON.stringify(ex));
      return null;
    }
  }

  async addOrUpdateEvent(event: Event): Promise<string> {
    if (event.id) {
      return await this.updateEvent(event.id, event);
    } else {
      return await this.addEvent(event);
    }
  }

  reportEvent(eventId: string, reason: string): Promise<boolean> {
    const reportEvent = httpsCallable<unknown, boolean>(this.functions, 'reportEvent');
    return reportEvent({ eventId, reason }).then(() => true, () => false);
  }

  setWarning(warning: Warning) {
    const docRef = doc(this.firestore, "settings", "warning");
    return from(setDoc(docRef, { ...warning })).pipe(
      map(() => true),
      catchError((error) => {
        this.rollbar.error('Error setWarning', JSON.stringify(error));
        return of(null);
      }));
  }

}
